import React from 'react';

const AboutEducation = () => (
  <section className="aq-feature-bg pt-8 pb-8 bg-grey text-white">
    <div className="container-fluid">
      <div className="component-wrap">
        <div className="row">
          <div className="col-lg-6 pb-4 pb-lg-0 d-flex align-items-center">
            <div>
              <h1 className="h1-two-line-v2 blue mb-15 mt-6 text-center">
                <span>Schools</span>
              </h1>
              <h3 className="text-black pt-4">
                AquaSmart is South East Water’s water efficiency and
                environmental programme, currently aimed at Key Stage 1
                children.
              </h3>
              <div>
                <p className="text-black">
                  The programme has missions and activities for children to
                  complete with the aim of educating and changing behaviours
                  from an early age. Our aim is to encourage children to be
                  water efficient and help the environment!
                </p>
                <p className="text-black">
                  <span className="font-weight-bold text-dark">
                    For teachers:
                  </span>{' '}
                  there is a teacher guide, 5 teacher feedback sheets, a spot
                  the difference answer sheet and a quiz answer sheet. There’s
                  also an overall feedback sheet.
                </p>
                <p className="text-black">
                  <span className="font-weight-bold text-dark">
                    For children:{' '}
                  </span>
                  there are 5 activity sheets, 5 activity feedback sheets and 1
                  quiz sheet.
                </p>
              </div>
              <hr className="my-5" />
              <div>
                <p className="text-black">
                  Once completed please send your feedback sheets to:{' '}
                </p>
                <p className="text-secondary">
                  AquaSmart, South East Water, Rocfort Road, Snodland, Kent, ME6
                  5AH{' '}
                </p>{' '}
                <p className="text-black">or email them to: </p>
                <p className="text-secondary">
                  {' '}
                  <a href="mailto://aquasmart@southeastwater.co.uk">
                    aquasmart@southeastwater.co.uk{' '}
                  </a>
                </p>{' '}
                <p className="text-black mt-5">
                  We can then send children certificates for completing all
                  activities and an overall certificate for the school. If your
                  school is within a South East Water supply area please do get
                  in touch to see how we can support your school with further
                  resources.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <img
              src="/images/Characters/Nero-Face-BG.svg"
              className="w-100 p-8"
              alt="Aquanaut Isla"
            />
          </div> */}
        </div>
      </div>
    </div>
  </section>
);

export default AboutEducation;
