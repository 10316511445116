import React from 'react';

const SchoolResources = () => (
  <section className="pt-8 pb-8 bg-green text-white">
    <div className="container-fluid">
      <div className="component-wrap">
        <div className="row">
          <div className="col-lg-6 pb-4 pb-lg-0 d-flex align-items-center">
            <div>
              <h1 className="h1-two-line-v2 white mb-15 mt-6 text-center">
                <span>Resources</span>
              </h1>
              <br />
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            <ul className=" ml-0" style={{ listStyleType: 'none' }}>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Teachers-Guide 25.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Teachers guide
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Activity 1-We-are-the-Aquanauts 25.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Activity 1 - we are the Aquanauts{' '}
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Activity 2-Spot-the-difference 25.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Activity 2 - Spot the difference{' '}
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Spot-the-difference-Answers.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Spot the difference (answer sheet){' '}
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Activity 3 -Water-Saving-Poster.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Activity 3 - Water saving poster
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Activity 4a-Water-Saving-Game 25.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Activity 4 - Water saving game
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Activity 4b-WaterGame_Dice_Counters.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Activity 4 - Water saving game (dice and counters)
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Activity 5 - All aboard the Aquabus.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Activity 5 - All aboard the Aquabus
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Activity 6-Quiz .pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Activity 6 - Quiz
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-Quiz-V5-Answers.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Activity 6 - Quiz answers
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-ChildrensFeedbackSheets.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Children's feedback sheets
                </a>
              </li>
              <li className="ml-sm-0 ml-md-5 mb-2">
                <img src="/images/General/file.svg" height="40" width="40" />{' '}
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/schools/SEW-TeacherFeedbackSheets.pdf"
                  className="ml-3 resource-link"
                >
                  {' '}
                  Teachers feedback sheets
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SchoolResources;
