import AboutEducation from '../components/AboutEducation';
import BroughtToYouBy from '../components/BroughtToYouBy';
import Layout from '../components/layout';
import React from 'react';
import SchoolResources from '../components/SchoolResources';
import Seo from '../components/Seo';

const SchoolsPage = ({ location }) => (
  <Layout page="schools" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Schools',
        seoDescription:
          'Please share your opinions on AquaSmart we would love to here from you',
      }}
    />
    <AboutEducation />
    <SchoolResources />
    <BroughtToYouBy />
  </Layout>
);

export default SchoolsPage;
